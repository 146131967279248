import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
// import { useTranslation } from 'react-i18next';

import { theme } from 'src/constants/theme';
import { Draggable } from 'react-beautiful-dnd';
import {
  DealFlowCard,
  deleteDealFlowCard,
  updateDealFlowCard,
} from 'src/slices/dealFlowCard';
import {
  Badge,
  Button,
  Comment,
  Form,
  List,
  Popconfirm,
  Typography,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import TextArea from 'antd/es/input/TextArea';
import { useAppSelector } from 'src/hooks/store';
import { KanbanNote } from './KanbanNote';
import { useDispatch } from 'react-redux';
import { createDealFlowNote, getDealFlowNotes } from 'src/slices/dealFlowNote';
import Text from 'antd/lib/typography/Text';
import { useHistory } from 'react-router-dom';

interface Props {
  cardId: string;
  index: number;
  columnId: string;
  projectId: string;
}

const useStyles = createUseStyles({
  cardCover: {
    backgroundColor: '#f2f2fe',
    borderColor: theme.palette.common.white,
    borderRadius: '0.3125vw',
    marginBottom: '10.79%',
    padding: '12.95% 9.71%',
    borderTop: 'solid 1px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    overflow: 'hidden',
  },
  notesBadge: {
    backGroundColor: theme.palette.primary,
  },
  modalCard: {
    width: '100%',
  },
  title: {
    fontSize: '0.875vw',
    textOverflow: 'ellipsis',
  },
  cardTitle: {
    fontSize: '0.875vw',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: '0.875vw',
  },
  notesList: {
    maxHeight: '84.75%',
    overflow: 'scroll',
  },
  modal: {
    width: '1000px',
    '&.ant-modal-wrap': {
      overflow: 'hidden',
    },
  },
  removeButton: {
    backgroundColor: '',
  },
});

const MAX_NOTES = 5;

export const KanbanCard: React.VFC<Props> = ({
  cardId,
  index,
  columnId,
  projectId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { cards, user } = useAppSelector(
    ({ dealFlowCard: { cards }, auth: { user } }) => ({
      cards,
      user,
    }),
  );

  const noteLoading = useAppSelector(({ dealFlowNote: { loading } }) => ({
    loading,
  })).loading;

  const card = useMemo<DealFlowCard>(() => cards[cardId], [cards, cardId]);

  const [showModal, setShowModal] = useState<string>('');
  const [editedComment, setEditedComment] = useState<string>('');
  const [displayedNotes, setDisplayedNotes] = useState<string[]>([]);

  useEffect(() => {
    if (showModal) {
      dispatch(getDealFlowNotes(cardId));
      if (card) setDisplayedNotes(card.notes.slice(0, MAX_NOTES));
    }
  }, [showModal]);

  const onEditComment = (value: string) => {
    setEditedComment(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangeCardName = (cardName: string) => {
    if (cardName !== card.name)
      dispatch(
        updateDealFlowCard({
          body: { name: cardName },
          cardId: card._id,
          dealFlowId: card.dealFlow,
        }),
      );
  };

  const onLoadMoreNotes = () => {
    setDisplayedNotes([
      ...displayedNotes,
      ...card.notes.slice(
        displayedNotes.length,
        displayedNotes.length + MAX_NOTES,
      ),
    ]);
  };

  const onSubmitComment = () => {
    user &&
      dispatch(
        createDealFlowNote({
          body: {
            content: editedComment,
            card: card._id,
            author: user?._id,
          },
          dealFlowId: card.dealFlow,
        }),
      );
    setEditedComment('');
  };

  const onShowMecene = () => {
    history.push(`/donor/${card.donor}?projectId=${projectId}`);
  };

  const onShowNgo = () => {
    history.push(`/ngo/${card.ngo}`);
  };

  const onRemoveDonor = () => {
    dispatch(
      deleteDealFlowCard({
        body: {
          columnId: columnId,
          projectId: projectId,
          donorId: card.donor,
        },
        cardId: cardId,
      }),
    );
  };

  const onRemoveNgo = () => {
    dispatch(
      deleteDealFlowCard({
        body: {
          columnId: columnId,
          callForTenderId: projectId,
          ngoId: card.ngo,
        },
        cardId: cardId,
      }),
    );
  };

  const renderCommentEditor = (
    onChange: (value: string) => void,
    onSubmit: () => void,
    onShowMecene: () => void,
    value: string,
  ) => (
    <Form>
      <Form.Item>
        <Button htmlType="submit" onClick={onShowMecene} type="primary">
          Voir la fiche du {user?.ngoId ? 'mécène' : 'porteur de projet'}
        </Button>
      </Form.Item>
      <Form.Item>
        <Popconfirm
          title={
            user?.ngoId
              ? 'Êtes-vous sûr de vouloir retirer ce mécène ?'
              : 'Êtes-vous sûr de vouloir retirer ce porteur de projet ?'
          }
          placement="left"
          onConfirm={() => {
            if (user?.ngoId) {
              return onRemoveDonor();
            } else {
              return onRemoveNgo();
            }
          }}
          okText="Oui"
          cancelText="Non"
        >
          <Button htmlType="submit" danger>
            Retirer des {user?.ngoId ? 'mécènes' : 'porteurs de projets'} suivis
          </Button>
        </Popconfirm>
      </Form.Item>
      <Form.Item>
        <TextArea
          rows={4}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          placeholder="Écrivez votre commentaire ici"
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" onClick={onSubmit} type="primary">
          Ajouter un commentaire
        </Button>
      </Form.Item>
    </Form>
  );

  const renderCardTitle = () => (
    <Text
      className={classes.title}
      // editable={
      //   card.donor
      //     ? false
      //     : {
      //         onChange: (value: string) => {
      //           onChangeCardName(value);
      //         },
      //         icon: <EditOutlined />,
      //       }
      // }
      onClick={() => {
        user?.ngoId ? onShowMecene() : onShowNgo();
      }}
    >
      {card.name}
    </Text>
  );

  useEffect(() => {
    if (
      displayedNotes &&
      card?.notes &&
      displayedNotes.length < card.notes.length &&
      displayedNotes.length < 5
    ) {
      onLoadMoreNotes();
    }
  }, [displayedNotes?.length, card?.notes?.length]);

  const renderLoadMoreNotes = () =>
    displayedNotes.length < card.notes.length ? (
      <Button onClick={onLoadMoreNotes}>Charger plus</Button>
    ) : null;

  const renderCard = () => {
    return (
      <div className={classes.modalCard}>
        <Comment
          content={renderCommentEditor(
            onEditComment,
            onSubmitComment,
            user?.ngoId ? onShowMecene : onShowNgo,
            editedComment,
          )}
        />
        <Typography className={classes.subtitle}>Commentaires</Typography>
        <List
          itemLayout="vertical"
          className={classes.notesList}
          dataSource={displayedNotes}
          loadMore={renderLoadMoreNotes()}
          renderItem={(noteId) => (
            <li>
              <KanbanNote noteId={noteId} />
            </li>
          )}
          loading={noteLoading}
          locale={{ emptyText: 'Pas de commentaire' }}
        />
      </div>
    );
  };
  if (!card) return <></>;
  return (
    <Draggable draggableId={cardId} index={index}>
      {(cardProvided) => (
        <div
          ref={cardProvided.innerRef}
          {...cardProvided.draggableProps}
          {...cardProvided.dragHandleProps}
        >
          <div
            className={classes.cardCover}
            onClick={() => {
              setShowModal(card._id);
            }}
          >
            <Typography
              className={classes.cardTitle}
              style={{ userSelect: 'none' }}
            >
              {card.name}
            </Typography>
            <Badge
              count={card.notes.length}
              style={{
                marginTop: '-0.875vw',
                marginRight: '-0.375vw',
                background: theme.palette.orange[0],
                height: '1.25vw',
                minWidth: '1.25vw',
                fontFamily: "'Baloo 2'",
                fontSize: '1.25vw',
                lineHeight: '1',
                padding: '0',
                color: theme.palette.common.white,
                borderColor: theme.palette.orange[0],
              }}
            />
          </div>

          <Modal
            title={renderCardTitle()}
            footer={null}
            onCancel={() => setShowModal('')}
            visible={showModal == card._id}
            style={{ width: '62.5vw' }}
          >
            {renderCard()}
          </Modal>
        </div>
      )}
    </Draggable>
  );
};

import { Card, Image } from 'antd';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { SortedClassification } from '../../hooks/useSortedClassification';
import { Donor } from '../../slices/donor';
import { getDonorUrl } from '../../utils/donors/donors.utils';
const { Meta } = Card;

const useStyles = createUseStyles({
  'ant-card-meta-description': {
    fontSize: 12,
    color: '#5050F6 !important',
    textDecoration: 'underline !important',
    '&:hover': {
      color: 'green !important',
    },
    whiteSpace: 'nowrap', // Prevents text from wrapping to next line
    overflow: 'hidden', // Hides overflow text
    textOverflow: 'ellipsis', // Adds ellipsis to overflow text
  },
  title: {
    fontFamily: "'Baloo 2'",
    fontSize: '18px !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    display: '-webkit-box !important',
    '-webkit-line-clamp': '2 !important',
    '-webkit-box-orient': 'vertical !important',
    lineHeight: '24px !important',
    maxHeight: '54px !important', // Adjusted based on new lineHeight
    width: '100%', // Ensuring full width is used
    whiteSpace: 'normal !important', // Override to allow wrapping
  },
});

export const DonorCard = ({
  donor,
  sustainableDevelopmentGoals,
}: {
  donor: Donor;
  sustainableDevelopmentGoals:
    | SortedClassification['sustainableDevelopmentGoals']
    | undefined;
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [imgUrl, setImgUrl] = React.useState(`/images/oddCards/mecene.png`);

  const checkImageUrl = async (url: string) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok; // Returns true if the status code is 2xx
    } catch (error) {
      console.error('Failed to fetch image URL:', error);
      return false;
    }
  };

  const getImgUrl = async (): Promise<string> => {
    if (donor.logoUrl) {
      return donor.logoUrl;
    }
    if (donor.ogImageUrl) {
      const isOgImageOk = await checkImageUrl(donor.ogImageUrl);
      if (isOgImageOk) {
        return donor.ogImageUrl;
      }
    }
    if (
      sustainableDevelopmentGoals &&
      donor.sustainableDevelopmentGoals &&
      donor.sustainableDevelopmentGoals.length > 0
    ) {
      const sdg = sustainableDevelopmentGoals.find(
        (sdg) => sdg._id === donor.sustainableDevelopmentGoals?.[0],
      );
      if (sdg) {
        return `/images/oddCards/odd_${sdg.number}.png`;
      }
    }
    return `/images/oddCards/mecene.png`;
  };

  useEffect(() => {
    getImgUrl().then((url) => setImgUrl(url));
  }, []);

  return (
    <Card
      hoverable
      style={{
        width: 250,
        height: 240,
        marginBottom: '2dvh',
        borderRadius: 8,
        boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
      }}
      cover={
        <Image
          alt="example"
          style={{
            width: 250,
            height: 130,
            objectFit: 'cover',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
          src={imgUrl}
        />
      }
      onClick={() => history.push(`/donor/${donor._id}`)}
    >
      <Meta
        title={<div className={classes.title}>{donor.name}</div>}
        description={
          <a
            onClick={(e) => {
              e.stopPropagation();
              window.open(getDonorUrl(donor), '_blank');
            }}
            className={classes['ant-card-meta-description']}
          >
            {getDonorUrl(donor)}
          </a>
        }
      />
    </Card>
  );
};

import { notification, TablePaginationConfig } from 'antd';
import { min } from 'lodash';
import { parse, stringify } from 'query-string';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation, useHistory } from 'react-router';
import { useSortedClassification } from 'src/hooks/useSortedClassification';
import {
  FinancingNeed,
  resetFinancingNeedEvent,
} from 'src/slices/financingNeed';
import { DonorCards } from '../components/DonorCards';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  Donor,
  DONORS_MAX_RESULTS,
  searchDonorsByFinancingNeed,
} from '../slices/donor';
import { fetchCallForTendersCountForDonor } from '../slices/callForTender';

interface Props {
  financingNeed: FinancingNeed;
}

const useStyles = createUseStyles({
  projectDonorsList: { marginTop: '-2.22vh', paddingTop: '1.65%' },
});

export const ProjectDonorsCards: React.VFC<Props> = ({ financingNeed }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useLocation().search;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [queryParamsObject, setQueryParamsObject] = useState<
    Record<string, unknown>
  >(parse(query, { arrayFormat: 'bracket' }));
  const MAX_DISPLAYED_PAGES_NUMBER = 10;

  const {
    donors,
    loading,
    financingNeedEvent,
    addDonorLoading,
    removeDonorLoading,
    searchResult,
    user,
    offset,
  } = useAppSelector(
    ({
      donor: {
        donors,
        loading,
        event,
        suggestionsLoading,
        searchResult,
        suggestionsDonors,
        offset,
        classificationsFilter,
      },
      financingNeed: {
        addDonorLoading,
        removeDonorLoading,
        event: financingNeedEvent,
      },
      auth: { user },
      callForTender: { callForTendersCounts },
    }) => ({
      donors,
      classificationsFilter,
      loading,
      event,
      financingNeedEvent,
      suggestionsLoading,
      addDonorLoading,
      removeDonorLoading,
      searchResult,
      suggestionsDonors,
      user,
      offset,
      callForTendersCounts,
    }),
  );

  const classification = useSortedClassification();

  const fetchDonors = (page: number) => {
    updateQueryParams({ page });
    dispatch(
      searchDonorsByFinancingNeed({
        id: financingNeed._id,
        searchParameters: {
          offset: (page - 1) * DONORS_MAX_RESULTS,
        },
      }),
    );
  };

  const updateQueryParams = (params: Record<string, unknown>) => {
    const updatedQueryParamsObject = { ...queryParamsObject, ...params };
    history.replace({
      search: stringify(updatedQueryParamsObject, { arrayFormat: 'bracket' }),
    });
    setQueryParamsObject(updatedQueryParamsObject);
  };

  useEffect(() => {
    if (!addDonorLoading || !removeDonorLoading) {
      fetchDonors(currentPage);
    }
  }, [addDonorLoading, removeDonorLoading]);

  useEffect(() => {
    if (financingNeedEvent) {
      financingNeedEvent.error
        ? notification.error({
            message:
              financingNeedEvent.action == 'addDonor'
                ? "Ce mécène n'a pas pu être ajouté à vos mécènes suivis"
                : "Ce mécène n'a pas pu être retiré de vos mécènes suivis",
            duration: 2,
          })
        : notification.success({
            message:
              financingNeedEvent.action == 'addDonor'
                ? 'Ce mécène a été ajouté à vos mécènes suivis'
                : 'Ce mécène a été retiré de vos mécènes suivis',
            duration: 2,
          });
      dispatch(resetFinancingNeedEvent());
    }
  }, [financingNeedEvent]);

  useEffect(() => {
    if (
      searchResult &&
      searchResult?.resultsIds &&
      searchResult?.resultsIds.length
    ) {
      const donorIds = searchResult.resultsIds.map((id) => id);
      dispatch(fetchCallForTendersCountForDonor(donorIds));
    }
  }, [searchResult?.resultsIds]);

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 1;
    setCurrentPage(newSearchPage);
    fetchDonors(newSearchPage);
  };

  if (!user || !donors) {
    return <FullscreenSpin />;
  }

  return (
    <div className={classes.projectDonorsList}>
      <DonorCards
        classification={classification}
        donors={
          searchResult?.resultsIds?.reduce(
            (list: Donor[], donorId) =>
              donors.byId[donorId] ? [...list, donors.byId[donorId]] : list,
            [],
          ) || null
        }
        onTableChange={onTableChange}
        loading={loading}
        pagination={{
          total: min([
            searchResult?.resultsCount,
            DONORS_MAX_RESULTS * MAX_DISPLAYED_PAGES_NUMBER,
          ]),
          current: offset / DONORS_MAX_RESULTS + 1,
          pageSize: DONORS_MAX_RESULTS,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

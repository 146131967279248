import { Button, Card, Divider, Radio, RadioChangeEvent } from 'antd';

import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getDonor } from '../../slices/donor';
import { Offer } from '../../slices/financingNeed';
import { getNgo } from '../../slices/ngo';
import offerIcon from '../../assets/icons/offerIcon.svg';
import { Pricing } from '../../pages/Stripe/Payment';
import { useHistory } from 'react-router-dom';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  offersContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainTitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '2.2vw !important',
    lineHeight: '1 !important',
    textAlign: 'center',
    marginBottom: '1.5vw !important',
  },
  secondaryTitle: {
    marginTop: '2vw',
    marginBottom: '2vw',
    width: '85%',
    fontFamily: "'Baloo 2'",
    fontWeight: '300',
    fontSize: '1.5vw !important',
    lineHeight: '1 !important',
    textAlign: 'center',
    color: '#5050F6',
  },
  offersBox: {
    marginTop: '3vw',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    gap: '2vw',
    marginBottom: '2.5vw',
  },
  cardHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    fontFamily: "'Baloo 2'",
    fontWeight: '300',
    fontSize: '1.3vw !important',
    lineHeight: '1 !important',
    textAlign: 'left',
    marginBottom: '1vh !important',
    color: '#5050F6',
  },
  cardPrice: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'baseline',
    width: '100%',
    marginBottom: '1vw',
  },
  cardPrice1: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '2.2vw !important',
    lineHeight: '1 !important',
    textAlign: 'left',
    marginBottom: '1vh !important',
    color: '#171717',
  },
  cardPriceCrossed: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.8vw !important',
    textAlign: 'left',
    marginBottom: '1vh !important',
    color: '#A0ABBB',
    textDecoration: 'line-through',
  },
  cardPrice2: {
    fontFamily: "'Baloo 2'",
    fontWeight: '300',
    fontSize: '1vw !important',
    lineHeight: '1 !important',
    textAlign: 'left',
    marginBottom: '1vh !important',
    marginLeft: '0.5vw',
    color: '#A3A3A3',
  },
  cardInfoComplete: {
    fontFamily: "'Baloo 2'",
    fontWeight: '300',
    fontSize: '1vw !important',
    lineHeight: '1 !important',
    textAlign: 'left',
    marginBottom: '1vh !important',
    color: 'white',
    backgroundColor: '#10B981',
    padding: '1vw',
  },

  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1vw',
  },
  offerArgBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  offerArgIcon: {},
  offerArgText: {
    fontFamily: "'Baloo 2'",
    fontWeight: '300',
    fontSize: '1.3vw !important',
    lineHeight: '1 !important',
    color: '#171717',
    marginTop: '0.2vw',
    marginLeft: '1vw',
  },
  paymentButton: {
    marginTop: '2vw',
    fontFamily: "'Baloo 2'",
    fontWeight: '300',
    fontSize: '1.3vw !important',
    lineHeight: '1 !important',
    backgroundColor: '#EEF2FF',
    color: '#6366F1',
    alignContent: 'center',
    padding: '1.5vw 1vw 3vw 1vw',
    borderRadius: '0.5vw',
  },
  remark: {
    marginTop: '2vw',
    color: '#737373',
    fontFamily: "'Baloo 2'",
    fontWeight: '300',
    fontSize: '1.2vw !important',
    lineHeight: '1 !important',
  },
  offersTitleComplete: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.7vw !important',
    lineHeight: '1 !important',
    textAlign: 'center',
    marginBottom: '1vh !important',
    textDecoration: 'underline',
    textDecorationColor: '#10B981',
    textUnderlineOffset: '0.5vw',
    textDecorationThickness: '0.2vw',
  },
  title: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.6875vw !important',
    lineHeight: '1 !important',
    margin: '0 0 15px 0 !important',
    textAlign: 'center',
  },
  subtitle: {
    // fontWeight: 'bold',
    fontSize: '1.4vw',
    lineHeight: 1,
    marginBottom: '4.26%',
  },
  discount: {
    fontWeight: 'bold',
    fontSize: 'calc(5px + 0.6vw)',
    lineHeight: 1,
    color: '#ff2255',
  },
  text: {
    fontWeight: 'bold',
    fontSize: 'calc(5px + 0.6vw)',
    lineHeight: 1,
  },
  offersRow: {},
  cardVisibility: {
    borderRadius: '5%',
    backgroundColor: 'white',
    paddingTop: '5vh',
    paddingBottom: '3vh',
  },
  cardComplete: {
    borderRadius: '5%',
    backgroundColor: 'white',
    paddingTop: '5vh',
    paddingBottom: '3vh',
    '&:hover': {
      boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
    },
  },
  table: {
    margin: '0 10vh',
    '& tr': {
      '& td': {
        paddingBottom: '2%',
      },
    },
  },
  radioGroup: {
    '& .ant-radio-button-wrapper': {
      // no border
      border: 'none',
    },
    '& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)':
      {
        color: '#4F46E5 !important',
        background: '#EEF2FF !important',
        borderRadius: '6px',
        border: 'none',
      },
    '& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before':
      {
        backgroundColor: 'transparent !important',
        border: 'none !important',
      },
    '& .ant-radio-button-wrapper:not(:first-child)::before': {
      display: 'none',
    },
  },
});

interface SubscriptionProps {
  projectId: string;
  type: 'ngo' | 'donor';
  offerCompleteExists: string;
  projectName?: string;
}

export const Offers: React.VFC<SubscriptionProps> = ({
  projectId,
  type,
  offerCompleteExists,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isMonthly, setIsMonthly] = React.useState(
    type === 'ngo' ? 'true' : 'false',
  );
  const { ngo, user } = useAppSelector(({ ngo: { ngo }, auth: { user } }) => ({
    ngo,
    user,
  }));

  if (type === 'ngo') {
    useEffect(() => {
      if (user?.ngoId) {
        dispatch(getNgo(user.ngoId));
      }
    }, [user?.ngoId]);
  }

  if (type === 'donor') {
    useEffect(() => {
      if (user?.donorId) {
        dispatch(getDonor(user.donorId));
      }
    }, [user?.donorId]);
  }

  function renderArgs() {
    return (
      <>
        <div className={classes.offerArgBox}>
          <div className={classes.offerArgIcon}>
            <img src={offerIcon} />
          </div>
          <div className={classes.offerArgText}>
            Travaillez sur 10{' '}
            {ngo
              ? 'projets'
              : 'programmes de soutien (appels à projet, prix, bourse...)'}{' '}
            simultanément.
          </div>
        </div>
        <div className={classes.offerArgBox}>
          <div className={classes.offerArgIcon}>
            <img src={offerIcon} />
          </div>
          <div className={classes.offerArgText}>
            {ngo
              ? 'Identifiez les mécènes** les plus pertinents grâce à nos algorithmes.'
              : 'Identifiez les associations les plus pertinents grâce à nos algorithmes.'}
          </div>
        </div>
        <div className={classes.offerArgBox}>
          <div className={classes.offerArgIcon}>
            <img src={offerIcon} />
          </div>
          <div className={classes.offerArgText}>
            {type === 'ngo'
              ? 'Soyez notifié par mail des nouveaux mécènes en France.'
              : 'Soyez notifié par mail des nouvelles assos en France.'}
          </div>
        </div>
        <div className={classes.offerArgBox}>
          <div className={classes.offerArgIcon}>
            <img src={offerIcon} />
          </div>
          <div className={classes.offerArgText}>
            {type === 'ngo'
              ? 'Identifiez les programmes de soutien (appels à projets, prix, bourse etc...) les plus pertinents.'
              : 'Identifiez les appels à projets les plus pertinents.'}
          </div>
        </div>
        <div className={classes.offerArgBox}>
          <div className={classes.offerArgIcon}>
            <img src={offerIcon} />
          </div>
          <div className={classes.offerArgText}>
            {type === 'ngo'
              ? 'Soyez notifié par mail des nouveaux programmes de soutien.'
              : 'Soyez notifié par mail des nouveaux appels à projet.'}
          </div>
        </div>
        <div className={classes.offerArgBox}>
          <div className={classes.offerArgIcon}>
            <img src={offerIcon} />
          </div>
          <div className={classes.offerArgText}>
            Suivez l&apos;avancement de vos démarches.
          </div>
        </div>
        <div className={classes.offerArgBox}>
          <div className={classes.offerArgIcon}>
            <img src={offerIcon} />
          </div>
          <div className={classes.offerArgText}>
            Offre sans engagement de durée. Annulable en 1 clic.
          </div>
        </div>
      </>
    );
  }

  function checkoutOffer(annualResource: Pricing, offer: Offer) {
    history.push(
      `/checkout/${projectId}/${annualResource}/${type}/${offer.toString()}/${isMonthly}`,
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.offersContainer}>
        <div className={classes.mainTitle}>
          Ne perdez plus de temps et accélérez vos recherches de{' '}
          {type === 'ngo' ? 'mécènes' : 'porteurs de projet'} pour tous vos{' '}
          {type === 'ngo' ? 'projets' : 'programmes de soutien'} !
        </div>
        <div>
          <Button
            style={{
              color: 'blue',
              backgroundColor: 'transparent',
            }}
            type="text"
          >
            <span
              style={{
                fontSize: '1.3vw',
                textDecoration: 'underline',
                textUnderlineOffset: '0.5vw',
              }}
            >
              Offre Nationale
            </span>
          </Button>
        </div>
        <div className={classes.secondaryTitle}>
          L’offre Nationale vous identifie des{' '}
          {type === 'ngo' ? 'mécènes' : 'porteurs de projet'} sur l’ensemble du
          territoire français
        </div>
        {type === 'ngo' && (
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '8px',
              padding: '4px',
            }}
          >
            <Radio.Group
              options={[
                {
                  label: (
                    <div
                      style={{
                        display: 'flex',
                        gap: '8px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <>Offre annuelle</>
                      <div
                        style={{
                          backgroundColor: '#ECFDF5',
                          borderRadius: '24px',
                          fontSize: '12px',
                          height: '24px',
                          color: '#10B981',
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '0 8px',
                        }}
                      >
                        <div>-20%</div>
                      </div>
                    </div>
                  ),
                  value: 'false',
                },
                { label: 'Offre mensuelle', value: 'true' },
              ]}
              onChange={({ target: { value } }: RadioChangeEvent) => {
                console.log('radio2 checked', value);
                setIsMonthly(value);
              }}
              value={isMonthly}
              optionType="button"
              buttonStyle="solid"
              style={{ marginTop: '0px' }}
              className={classes.radioGroup}
            />
          </div>
        )}
        <div style={{ marginTop: '3vw' }}>
          <div className={classes.offersTitleComplete}>
            <div>Une offre adaptée à vos ressources</div>
          </div>
          <div className={classes.offersBox}>
            <Card>
              <div className={classes.cardHeader}>
                <div className={classes.cardTitle}>Offre Nationale</div>
                {isMonthly === 'true' ? (
                  <div className={classes.cardPrice}>
                    <div className={classes.cardPrice1}>39€</div>
                    <div className={classes.cardPrice2}>
                      / mois sans engagement de durée
                    </div>
                  </div>
                ) : (
                  <div className={classes.cardPrice}>
                    <div className={classes.cardPriceCrossed}>360€</div>
                    <div className={classes.cardPrice1}>288€</div>
                    <div className={classes.cardPrice2}>/an</div>
                  </div>
                )}
                <div className={classes.cardInfoComplete}>
                  Pour les {type === 'ngo' ? 'associations' : 'mécènes'}{' '}
                  disposant de moins de 100 000 euros de{' '}
                  {'ressources annuelles* '}
                  {type === 'donor' && 'ou assimilés*'}
                </div>
              </div>
              <Divider />
              <div className={classes.cardBody}>
                {renderArgs()}
                <Button
                  className={classes.paymentButton}
                  onClick={() => checkoutOffer(Pricing.inf100K, Offer.complete)}
                  disabled={offerCompleteExists === 'true' ? true : false}
                >
                  Activez les fonctionnalités
                </Button>
              </div>
            </Card>
            <Card>
              <div className={classes.cardHeader}>
                <div className={classes.cardTitle}>Offre Nationale</div>
                {isMonthly === 'true' ? (
                  <div className={classes.cardPrice}>
                    <div className={classes.cardPrice1}>60€</div>
                    <div className={classes.cardPrice2}>
                      / mois sans engagement de durée
                    </div>
                  </div>
                ) : (
                  <div className={classes.cardPrice}>
                    <div className={classes.cardPriceCrossed}>720€</div>
                    <div className={classes.cardPrice1}>576€</div>
                    <div className={classes.cardPrice2}>/an</div>
                  </div>
                )}
                <div className={classes.cardInfoComplete}>
                  Pour les {type === 'ngo' ? 'associations' : 'mécènes'}{' '}
                  disposant entre 100 000 euros et 500 000 euros de ressources
                  annuelles* {type === 'donor' && 'ou assimilés*'}
                </div>
              </div>
              <Divider />
              <div className={classes.cardBody}>
                {renderArgs()}
                <Button
                  className={classes.paymentButton}
                  onClick={() => checkoutOffer(Pricing.inf500K, Offer.complete)}
                  disabled={offerCompleteExists === 'true' ? true : false}
                >
                  Activez les fonctionnalités
                </Button>
              </div>
            </Card>
            <Card>
              <div className={classes.cardHeader}>
                <div className={classes.cardTitle}>Offre Nationale</div>
                {isMonthly === 'true' ? (
                  <div className={classes.cardPrice}>
                    <div className={classes.cardPrice1}>90€</div>
                    <div className={classes.cardPrice2}>
                      / mois sans engagement de durée
                    </div>
                  </div>
                ) : (
                  <div className={classes.cardPrice}>
                    <div className={classes.cardPriceCrossed}>1080€</div>
                    <div className={classes.cardPrice1}>864€</div>
                    <div className={classes.cardPrice2}>/an</div>
                  </div>
                )}
                <div className={classes.cardInfoComplete}>
                  Pour les {type === 'ngo' ? 'associations' : 'mécènes'}{' '}
                  disposant de plus de 500 000 euros de ressources annuelles*{' '}
                  {type === 'donor' && 'ou assimilés*'}
                </div>
              </div>
              <Divider />
              <div className={classes.cardBody}>
                {renderArgs()}
                <Button
                  className={classes.paymentButton}
                  onClick={() => checkoutOffer(Pricing.sup500K, Offer.complete)}
                  disabled={offerCompleteExists === 'true' ? true : false}
                >
                  Activez les fonctionnalités
                </Button>
              </div>
            </Card>
          </div>
          {type === 'ngo' && (
            <div className={classes.remark}>
              * Sommes de tous les comptes de classe 7 dans le compte de
              résultat comptable de l’année N-1.
            </div>
          )}
          {type === 'ngo' && (
            <div className={classes.remark}>
              **(Fondation reconnue publique, fondation d&apos;entreprise,
              fondation abritante, fondation abritée, fonds de dotation,
              entreprise)
            </div>
          )}
          {type === 'donor' && (
            <div className={classes.remark}>
              * Pour les entreprises, ressources allouées aux mécénats sur
              l’exercice N-1.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
